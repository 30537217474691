import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import withSnackbar from '@ui-lib/custom-components/Snackbar/withSnackbar';
import SnackbarAggregator from './SnackbarAggregator';
import { removeNotificationSnackbar, setModal, setSelectedNotification } from '../actions/NonContextualNotifications';
import { getUrlParams, getAction, getTagContent, triggerUfeEvent, triggerExternalUrl } from '../shared/utils';
import { default_cat } from '../shared/utils';

class NotificationHandler extends React.Component {

    state = {
        showAggregator : false,
        snackbarQueue : [],
    };

    getCategoryInfo = (notificationCategories) => {
        let { categories } = this.props;
        if (!notificationCategories || notificationCategories.length <= 0) {
            return default_cat;
        }
        return categories.find(e => e.id === notificationCategories[0].id);
    };

    handleClose = (notificationId, event) => {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        this.props.removeNotificationSnackbar(notificationId);
        this.setState({ snackbarQueue : this.state.snackbarQueue.filter(e => e.notificationId !== notificationId) });
    };

    handleRemoveSnackbar = (notificationId) => {
        let toRemove = this.state.snackbarQueue.find(e => e.notificationId === notificationId);
        this.props.closeSnackbar(toRemove.key);
        this.setState({ snackbarQueue : this.state.snackbarQueue.filter(e => e.notificationId !== notificationId) });
    };

    getPrimaryActionRender = (primaryAction) => {
        const { i18nProvider } = this.props;
        let content = getTagContent(primaryAction);
        if (typeof content === 'string') {
            if (content.includes('ufe://')) {
                return i18nProvider.Labeli18n(getAction(content));
            } else {
                return undefined;
            }
        } else {
            return content.name;
        }
    };

    ifValidCategory = (category) => {
        return category === 'success' && category === 'error' && category === 'warning' && category === 'unknown' && category === 'info';
    };

    componentDidUpdate(prevProps) {
        const { notificationSnackbar, i18nProvider, config, isPrivate, setSelectedNotification, isNotificationCenterOpen, notificationService } = this.props;
        if (isPrivate && !prevProps.isPrivate) {
            this.setState({ showAggregator : true });
        }
        if (this.state.showAggregator && !isPrivate && prevProps.notificationSnackbar.length > notificationSnackbar.length && notificationSnackbar.length === 0) {
            this.setState({ showAggregator : false });
        }
        if (prevProps.notificationSnackbar.length < notificationSnackbar.length) {
            let toAdd = notificationSnackbar.filter(e => !prevProps.notificationSnackbar.includes(e));
            if (notificationSnackbar.length >= 4 && toAdd.length > 0) {
                this.props.closeSnackbar();
            } else {
                if (!isPrivate) {
                    let queueToAdd = [];
                    toAdd.forEach(notificationToShow => {
                        let category = this.getCategoryInfo(notificationToShow.categories);
                        let primaryActionName = notificationToShow.primaryAction && this.getPrimaryActionRender(notificationToShow.primaryAction);
                        let key = this.props.enqueueSnackbar(notificationToShow.title, {
                            variant : this.ifValidCategory(category) ? category.name : 'unknown',
                            role : i18nProvider.Labeli18n(category ? category.name : 'info'),
                            autoHideDuration : config && config.snackbarTimeout ? config.snackbarTimeout : 10000,
                            onClick : () => {
                                if (!isNotificationCenterOpen) {
                                    setSelectedNotification(notificationToShow, true, notificationService);
                                    this.handleRemoveSnackbar(notificationToShow.id);
                                }
                            },
                            defaultActionConfig : notificationToShow.primaryAction && primaryActionName && {
                                callback : (event) => {
                                    if (event) {
                                        event.stopPropagation();
                                        event.preventDefault();
                                    }
                                    if (notificationToShow.primaryAction && notificationToShow.primaryAction.includes('ufe://')) {
                                        triggerUfeEvent(notificationToShow.primaryAction, this.props.setModal);
                                    } else {
                                        triggerExternalUrl(notificationToShow.primaryAction);
                                    }
                                    this.handleRemoveSnackbar(notificationToShow.id);
                                },
                                label : primaryActionName
                            },
                            onClose : (event) => {this.handleClose(notificationToShow.id, event);}
                        });
                        queueToAdd.push({ key : key, notificationId : notificationToShow.id });
                    });
                    this.setState({ snackbarQueue : this.state.snackbarQueue.concat(queueToAdd) });
                }
            }
        }
    }

    render() {
        const { notificationSnackbar } = this.props;
        const { showAggregator } = this.state;
        return notificationSnackbar.length >= 4 || showAggregator ? <SnackbarAggregator/> : <div id={'notificationHandler'}/>;
    }
}

const mapStateToProps = ({ notificationService : { NonContextualNotifications }, shell : { i18nProvider, isPrivate, services } }) => {
    return {
        notificationSnackbar : NonContextualNotifications.notificationSnackbar,
        categories : NonContextualNotifications.categories,
        config : NonContextualNotifications.config,
        isNotificationCenterOpen : NonContextualNotifications.isNotificationCenterOpen,
        notificationService : services.Notifications,
        i18nProvider,
        isPrivate
    };
};

const mapDispatchToProps = dispatch => bindActionCreators(
    { removeNotificationSnackbar, setSelectedNotification, setModal },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(NotificationHandler));