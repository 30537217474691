import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@ui-lib/core/styles';
import { bindActionCreators } from 'redux';
import Icon from '@ui-lib/core/Icon';
import Typography from '@ui-lib/core/Typography';
import IconButton from '@ui-lib/core/IconButton';
import classNames from '@ui-lib/core/classNames';
import { setSelectedNotification } from '../../actions/NonContextualNotifications';
import { getFormattedDate } from '../utils';
import Menu from '@ui-lib/core/Menu';
import MenuItem from '@ui-lib/core/MenuItem';

const styles = theme => ({
    root : {
        width : '100%',
        height : '72px',
        display : 'flex',
        padding : '16px 8px 16px 20px',
        cursor : 'pointer',
        '&:hover' : {
            backgroundColor : theme.palette.grey[100]
        },
        borderBottom : '1px solid ' + theme.palette.grey[400],
        position : 'relative',
    },
    rootSelected : {
        backgroundColor : theme.palette.primary.light,
        '&:hover' : {
            backgroundColor : theme.palette.primary.light
        },
    },
    iconContainer : {
        marginRight : '17px'
    },
    icon : {
        width : '16px',
        height : '16px',
        fontSize : '16px'
    },
    textContainer : {
        display : 'flex',
        flex : 1,
        flexDirection : 'column'
    },
    title : {
        marginBottom : '8px',
        color : theme.palette.text.primary,
        fontSize : '14px',
        fontWeight : 'normal'
    },
    titleUnread : {
        color : theme.palette.common.blue,
        fontWeight : 600
    },
    subtitle : {
        color : theme.palette.text.secondary
    },
    subtitleDivider : {
        borderRadius : '50%',
        width : '4px',
        height : '4px',
        margin : '2px 8px',
        display : 'inline-block',
        backgroundColor : theme.palette.text.secondary
    },
    //Icon Status
    iconSuccess : {
        color : theme.palette.common.green,
        '&:before' : {
            color : theme.palette.common.green + ' !important',
        }
    },
    iconWarning : {
        color : theme.palette.common.yellow
    },
    iconInfo : {
        color : theme.palette.common.blue,
        '&:before' : {
            backgroundColor : theme.palette.common.white,
            borderRadius : '50%'
        }
    },
    iconError : {
        color : theme.palette.error.main
    }
});

const ListItem = ({ classes, notification, category, selectedNotification, action = false, openCenter = true, setSelectedNotification, showSelected = false, i18nProvider, notificationService }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const onClick = () => {
        if (openCenter) {
            setSelectedNotification(notification, true, notificationService);
        } else {
            setSelectedNotification(notification, undefined, notificationService);
        }
    };

    const actionOnClick = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    function handleClose(event) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        setAnchorEl(null);
    }

    const handleDelete = (event) => {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        notificationService.delete(notification.id);
        handleClose();
    };

    const handleMarkAsReadOrUnread = (event) => {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        if (!notification.readAt) {
            notificationService.markRead(notification.id);
        } else {
            notificationService.markUnread(notification.id);
        }
        handleClose();
    };

    return (
        <div onClick={onClick} id={'notification' + notification.id} className={classNames(classes.root, {
            [classes.rootSelected] : showSelected && selectedNotification && selectedNotification.id === notification.id
        })}>
            <div className={classes.iconContainer}>
                <Icon className={classNames(category.icon, classes.icon, {
                    [classes.iconSuccess] : category.name.toUpperCase() === 'SUCCESS',
                    [classes.iconError] : category.name.toUpperCase() === 'ERROR',
                    [classes.iconInfo] : category.name.toUpperCase() === 'INFO',
                    [classes.iconWarning] : category.name.toUpperCase() === 'WARNING'
                })}/>
            </div>
            <div className={classes.textContainer}>
                <div className={classNames(classes.title, {
                    [classes.titleUnread] : !notification.readAt
                })}>
                    {notification.title}
                </div>
                <div className={classes.subtitle}>
                    <Typography variant={'overline'}>
                        {i18nProvider.Labeli18n(category.name)} <span className={classes.subtitleDivider}/> {getFormattedDate(notification.createdAt, i18nProvider)}
                    </Typography>
                </div>
            </div>
            {action && <div className={classes.action}>
                <IconButton onClick={actionOnClick}>
                    <Icon className={'icon-extrainformation'}/>
                </IconButton>
                {anchorEl && <Menu anchorEl={anchorEl} open={true} onClose={handleClose}>
                    <MenuItem onClick={handleMarkAsReadOrUnread}>{notification.readAt ? i18nProvider.Labeli18n('mark_as_unread') : i18nProvider.Labeli18n('mark_as_read')}</MenuItem>
                    <MenuItem onClick={handleDelete}>{i18nProvider.Labeli18n('delete')}</MenuItem>
                </Menu>}
            </div>}
        </div>
    );
};

const mapStateToProps = ({ notificationService : { NonContextualNotifications }, shell : { i18nProvider, services } }) => {
    return {
        selectedNotification : NonContextualNotifications.selectedNotification,
        notificationService : services.Notifications,
        i18nProvider,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setSelectedNotification
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ListItem));