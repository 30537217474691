export const ADD_NOTIFICATION = "ADD_CONTEXTUAL_NOTIFICATION";

export function addNotification(notification) {
    return {type: ADD_NOTIFICATION, notification}
}

export const REMOVE_NOTIFICATION = "REMOVE_CONTEXTUAL_NOTIFICATION";

export function removeNotification(notificationKey) {
    return {type: REMOVE_NOTIFICATION, notificationKey}
}