import React from 'react';
import { withStyles } from '@ui-lib/core/styles';
import classNames from '@ui-lib/core/classNames';
import Header from './Header';
import Body from './Body';
import { connect } from 'react-redux';

const styles = theme => ({
    root : {
        width : '100%',
        height : '100%',
        backgroundColor : theme.palette.background.default,
        border : '1px solid ' + theme.palette.grey[400]
    },
    header : {
        width : '100%',
        height : '60px',
        borderBottom : '1px solid ' + theme.palette.grey[400],
        display : 'flex'
    },
    body : {},
    isEmpty : {
        border : '0px'
    }
});

const NotificationCenter = ({ classes, isLoadingData, centerNotifications }) => {
    return (
        <div className={classes.root}>
            <div className={classNames(classes.header, {
                [classes.isEmpty] : !isLoadingData && centerNotifications.length <= 0
            })}>
                <Header/>
            </div>
            <div className={classes.body}>
                <Body/>
            </div>
        </div>
    );
};

const mapStateToProps = ({ notificationService : { NonContextualNotifications } }) => {
    return {
        isLoadingData : NonContextualNotifications.isLoadingData,
        centerNotifications : NonContextualNotifications.centerNotifications,
    };
};

export default connect(mapStateToProps)(withStyles(styles)(NotificationCenter));