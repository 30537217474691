import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    setKeywordsFilter, setStartDateFilter, setEndDateFilter,
    setReadFilter, setNotificationCenterFilterOpen, setIsLoadingMetadata, setIsLoadingData,
    setSelectedNotification, setCenterNotificationPage
} from '../../actions/NonContextualNotifications';
import { withStyles } from '@ui-lib/core/styles';
import Typography from '@ui-lib/core/Typography';
import TextField from '@ui-lib/core/TextField';
import FormControl from '@ui-lib/core/FormControl';
import Select from '@ui-lib/core/Select';
import InputLabel from '@ui-lib/core/InputLabel';
import MenuItem from '@ui-lib/core/MenuItem';
import Button from '@ui-lib/core/Button';
import AppliedFilters from './AppliedFilters';
import Calendar from '@ui-lib/custom-components/Calendar';

const styles = theme => ({
    filtersRoot : {
        padding : '20px',
        backgroundColor : theme.palette.background.default,
    },
    inputs : { display : 'flex' },
    keywordInput : {
        flex : 1,
        marginRight : '24px'
    },
    dateInputs : {
        width : '200px',
        marginRight : '24px',
        paddingTop : '16px'
    },
    isReadInput : {
        width : '200px',
        paddingTop : '16px'
    },
    actions : {
        display : 'flex',
        justifyContent : 'flex-end',
        marginTop : '50px'
    },
    applyButton : {
        marginLeft : '24px'
    },
    labelFix : {
        marginTop : '16px'
    },
    calendarLabelFix : {
        marginTop : '13px'
    }
});

export const defaultFilters = {
    keywords : '',
    startDate : undefined,
    endDate : undefined,
    isReadFilter : 'all'
};

const DAY = 60 * 60 * 24 * 1000;

const Filters = (
    {
        classes, isNotificationCenterFiltersOpen, setNotificationCenterFilterOpen,
        keywords, startDate, endDate, isReadFilter,
        setReadFilter, setEndDateFilter, setStartDateFilter, setKeywordsFilter,
        I18nProvider, lang, setIsLoadingData, setIsLoadingMetadata,
        setSelectedNotification, setCenterNotificationPage, selectedCategory, notificationService
    }
) => {

    const [state, setState] = React.useState({
        ...defaultFilters
    });

    const onChange = name => event => {
        // noinspection JSCheckFunctionSignatures
        if (event.target) {
            setState({ ...state, [name] : event.target.value });
        } else {
            setState({ ...state, [name] : event.startDate });
        }

    };

    const applyFilters = () => {
        if (state.keywords && state.keywords !== keywords) {
            setKeywordsFilter(state.keywords);
        }
        if (state.startDate && state.startDate !== startDate) {
            setStartDateFilter(state.startDate);
        }
        if (state.endDate && state.endDate !== endDate) {
            setEndDateFilter(state.endDate);
        }
        if (state.isReadFilter !== 'all' && state.isReadFilter !== isReadFilter) {
            setReadFilter(state.isReadFilter);
        }
        setNotificationCenterFilterOpen(false);
        setIsLoadingData(true);
        setIsLoadingMetadata(true);
        setCenterNotificationPage(1);
        setSelectedNotification(undefined);
        notificationService.getMetadata({
            keyword : state.keywords !== '' ? state.keywords : undefined,
            beginDate : state.startDate,
            endDate : state.endDate,
            status : state.isReadFilter !== 'all' ? state.isReadFilter : undefined
        }, true).then(() => {
            // noinspection JSCheckFunctionSignatures
            notificationService.get({
                page : 1,
                categoryId : selectedCategory.name !== 'total' ? selectedCategory.id : undefined,
                keywords : state.keywords !== '' ? state.keywords : undefined,
                startDate : state.startDate,
                endDate : state.endDate,
                isRead : state.isReadFilter !== 'all' ? state.isReadFilter : undefined
            }, false, true, true);
        }).catch(() => {});
    };

    const clearFilterState = () => {
        setState({ ...state, ...defaultFilters });
    };

    const actionButtonsDisabled = !state.keywords && !state.startDate && !state.endDate && state.isReadFilter === 'all';

    return (
        <>
            {isNotificationCenterFiltersOpen && <div className={classes.filtersRoot}>
                <Typography component={'div'} variant={'h4'}>
                    {I18nProvider.Labeli18n('filter')}
                </Typography>
                <div className={classes.inputs}>
                    <div className={classes.keywordInput}>
                        <TextField
                            fullWidth={true}
                            className={classes.labelFix}
                            onChange={onChange('keywords')}
                            value={state.keywords}
                            label={I18nProvider.Texti18n('search_for_text')}
                        />
                    </div>
                    <div className={classes.dateInputs}>
                        <div className={classes.calendarLabelFix}>
                            <Calendar
                                I18nProvider={I18nProvider}
                                lang={lang}
                                label={I18nProvider.Labeli18n('starting_date')}
                                startDate={state.startDate}
                                endLimit={!!state.endDate ? new Date(state.endDate.getTime() - DAY) : undefined}
                                onChange={onChange('startDate')}
                            />
                        </div>
                    </div>
                    <div className={classes.dateInputs}>
                        <div className={classes.calendarLabelFix}>
                            <Calendar
                                I18nProvider={I18nProvider}
                                lang={lang}
                                label={I18nProvider.Labeli18n('end_date')}
                                startLimit={!!state.startDate ? new Date(state.startDate.getTime() + DAY) : undefined}
                                startDate={state.endDate}
                                onChange={onChange('endDate')}
                            />
                        </div>
                    </div>
                    <div className={classes.isReadInput}>
                        <FormControl fullWidth={true}>
                            <InputLabel>{I18nProvider.Labeli18n('read_status_label')}</InputLabel>
                            <Select
                                value={state.isReadFilter}
                                onChange={onChange('isReadFilter')}
                            >
                                <MenuItem value={'all'}>{I18nProvider.Labeli18n('all_status')}</MenuItem>
                                <MenuItem value={'read'}>{I18nProvider.Labeli18n('read_status')}</MenuItem>
                                <MenuItem value={'unread'}>{I18nProvider.Labeli18n('unread_status')}</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </div>
                <div className={classes.actions}>
                    <Button disabled={actionButtonsDisabled} onClick={clearFilterState}>{I18nProvider.Labeli18n('clear')}</Button>
                    <Button disabled={actionButtonsDisabled} variant={'outlined'} onClick={applyFilters} className={classes.applyButton}>{I18nProvider.Labeli18n('apply')}</Button>
                </div>
            </div>}
            <AppliedFilters
                state={state}
                setState={setState}
            />
        </>
    );
};

const mapStateToProps = ({ notificationService : { NonContextualNotifications }, shell : { i18nProvider, services } }) => {
    return {
        isNotificationCenterFiltersOpen : NonContextualNotifications.isNotificationCenterFiltersOpen,
        keywords : NonContextualNotifications.keywords,
        startDate : NonContextualNotifications.startDate,
        endDate : NonContextualNotifications.endDate,
        isReadFilter : NonContextualNotifications.isReadFilter,
        I18nProvider : i18nProvider,
        lang : i18nProvider.currentLanguage,
        notificationService : services.Notifications,
        selectedCategory : NonContextualNotifications.selectedCategory,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setReadFilter,
        setEndDateFilter,
        setStartDateFilter,
        setKeywordsFilter,
        setNotificationCenterFilterOpen,
        setIsLoadingMetadata,
        setIsLoadingData,
        setSelectedNotification,
        setCenterNotificationPage
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Filters));