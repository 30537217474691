/* ================================ NOTIFICATION - Logic ============================================*/

export const REMOVE_NOTIFICATION_SNACKBAR = 'REMOVE_NOTIFICATION_SNACKBAR';

export function removeNotificationSnackbar(notificationId) {
    return { type : REMOVE_NOTIFICATION_SNACKBAR, notificationId };
}

export const ADD_NOTIFICATIONS = 'ADD_NON_CONTEXTUAL_NOTIFICATIONS';

export function addNotification(notification, showSnackbar = false, prepend = false) {
    return { type : ADD_NOTIFICATIONS, notification, showSnackbar, prepend };
}

export const ADD_CENTER_NOTIFICATIONS = 'ADD_NON_CONTEXTUAL_CENTER_NOTIFICATIONS';

export function addCenterNotification(notification, prepend = false) {
    return { type : ADD_CENTER_NOTIFICATIONS, notification, prepend };
}

export const SET_CENTER_NOTIFICATIONS = 'SET_CENTER_NOTIFICATIONS';

export function setCenterNotifications(notifications) {
    return { type : SET_CENTER_NOTIFICATIONS, notifications };
}

/* ================================ NOTIFICATION - UI ============================================*/

export const SET_NOTIFICATION_CENTER_OPEN = 'SET_NOTIFICATION_CENTER_OPEN';

export function setNotificationCenterOpen(isOpen) {
    return { type : SET_NOTIFICATION_CENTER_OPEN, isOpen };
}

export const SET_NOTIFICATION_QUICK_ACTION_OPEN = 'SET_NOTIFICATION_QUICK_ACTION_OPEN';

export function setNotificationQuickActionOpen(isOpen) {
    return { type : SET_NOTIFICATION_QUICK_ACTION_OPEN, isOpen };
}

export const SET_NOTIFICATION_CENTER_FILTER_OPEN = 'SET_NOTIFICATION_CENTER_FILTER_OPEN';

export function setNotificationCenterFilterOpen(isOpen) {
    return { type : SET_NOTIFICATION_CENTER_FILTER_OPEN, isOpen };
}

export const SET_NOTIFICATION_BADGE_CONTENT = 'SET_NOTIFICATION_BADGE_CONTENT';

export function setNotificationBadgeContent(content) {
    return { type : SET_NOTIFICATION_BADGE_CONTENT, content };
}

export const SET_SELECTED_CATEGORY = 'SET_SELECTED_CATEGORY';

export function setSelectedCategory(categoryId) {
    return { type : SET_SELECTED_CATEGORY, categoryId };
}

export const SET_SELECTED_NOTIFICATION = 'SET_SELECTED_NOTIFICATION';

export function setSelectedNotification(selectedNotification, isNotificationCenterOpen, notificationsService) {
    let newSelectedNotification;
    if (selectedNotification && !selectedNotification.readAt) {
        notificationsService.markRead(selectedNotification.id);
        newSelectedNotification = { ...selectedNotification, readAt : new Date() };
    } else {
        newSelectedNotification = selectedNotification;
    }

    return { type : SET_SELECTED_NOTIFICATION, selectedNotification : newSelectedNotification, isNotificationCenterOpen };
}

export const SET_NOTIFICATIONS_PAGE = 'SET_NOTIFICATIONS_PAGE';

export function setNotificationPage(page) {
    return { type : SET_NOTIFICATIONS_PAGE, page };
}

export const SET_CENTER_NOTIFICATIONS_PAGE = 'SET_CENTER_NOTIFICATIONS_PAGE';

export function setCenterNotificationPage(page) {
    return { type : SET_CENTER_NOTIFICATIONS_PAGE, page };
}

export const SET_IS_LOADING_METADATA = 'SET_IS_LOADING_METADATA';

export function setIsLoadingMetadata(value) {
    return { type : SET_IS_LOADING_METADATA, value };
}

export const SET_IS_LOADING_DATA = 'SET_IS_LOADING_DATA';

export function setIsLoadingData(value) {
    return { type : SET_IS_LOADING_DATA, value };
}

export const SET_MODAL = 'SET_MODAL';

export function setModal(isOpen = false, modalEvent, modalCallback) {
    return { type : SET_MODAL, isOpen, modalEvent, modalCallback };
}

/* ================================ NOTIFICATION - Filters ============================================*/

export const SET_KEYWORDS_FILTER = 'SET_KEYWORDS_FILTER';

export function setKeywordsFilter(keywords) {
    return { type : SET_KEYWORDS_FILTER, keywords };
}

export const SET_READ_FILTER = 'SET_READ_FILTER';

export function setReadFilter(readFilter) {
    return { type : SET_READ_FILTER, readFilter };
}

export const SET_START_DATE_FILTER = 'SET_START_DATE_FILTER';

export function setStartDateFilter(startDate) {
    return { type : SET_START_DATE_FILTER, startDate };
}

export const SET_END_DATE_FILTER = 'SET_END_DATE_FILTER';

export function setEndDateFilter(endDate) {
    return { type : SET_END_DATE_FILTER, endDate };
}

export const CLEAR_FILTERS = 'CLEAR_FILTERS';

export function clearFilters() {
    return { type : CLEAR_FILTERS };
}