import React from 'react';
import LoadingImage from '../../../../assets/illustration.svg';
import { withStyles } from '@ui-lib/core/styles';
import Typography from '@ui-lib/core/Typography';
import { connect } from 'react-redux';

const styles = theme => ({
    root : {
        display : 'flex',
        flexDirection : 'column',
        justifyContent : 'center',
        alignItems : 'center',
        padding : '42px 60px'
    },
    title : {
        marginTop : '32px'
    },
    subtitle : {
        marginTop : '4px',
        color : theme.palette.text.primary
    }
});

const EmptyList = ({ classes, i18nProvider }) => {
    return (
        <div className={classes.root}>
            <LoadingImage/>
            <Typography className={classes.title} variant={'h4'}>
                {i18nProvider.Labeli18n('no_notification')}
            </Typography>
            <Typography className={classes.subtitle} variant={'caption'}>
                {i18nProvider.Labeli18n('up_to_date')}
            </Typography>
        </div>
    );
};

const mapStateToProps = ({ shell : { i18nProvider } }) => {
    return {
        i18nProvider
    };
};

export default connect(mapStateToProps)(withStyles(styles)(EmptyList));