import React, { useState } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@ui-lib/core/styles';
import Alert from '@ui-lib/custom-components/Alert';
import { bindActionCreators } from 'redux';
import { removeNotification } from '../actions/ContextualNotifications';
import Fade from '@ui-lib/core/Fade';

const styles = theme => ({
    notificationRoot : {
        position : 'absolute',
        zIndex : theme.zIndex.customNotifications,
        top : '116px', //126 - 10 of top margin
        left : '50%',
        transform : 'translate(-50%)',
        display : 'flex',
        flexDirection : 'column',
        alignItems : 'center'
    },
    spacing : {
        marginTop : '10px'
    }
});

class NotificationsAlerts extends React.Component {

    notificationCount = 0;

    state = {
        notificationQueue : []
    };

    handleClose = notificationKey => () => {
        const { removeNotification } = this.props;
        removeNotification(notificationKey);
    };

    componentDidUpdate(prevProps, prevState) {
        const { contextualNotificationList } = this.props;
        if (contextualNotificationList.length > 3) {
            this.handleClose(contextualNotificationList[0].key)();
        }
    }

    render() {
        const { classes, contextualNotificationList } = this.props;
        if (contextualNotificationList.length <= 0) {
            // noinspection JSConstructorReturnsPrimitive
            return null;
        }
        return (
            <div className={classes.notificationRoot}>
                {contextualNotificationList.map((notification, index) => (
                    <Fade key={index} in={true}>
                        <div className={classes.spacing}>
                            <Alert severity={notification.severity} onClose={this.handleClose(notification.key)}>
                                {notification.message}
                            </Alert>
                        </div>
                    </Fade>
                ))}
            </div>
        );
    }
}

const mapStateToProps = ({ notificationService : { ContextualNotifications } }) => {
    return { contextualNotificationList : ContextualNotifications.contextualNotificationList };
};

const mapDispatchToProps = dispatch => bindActionCreators(
    { removeNotification },
    dispatch
);

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(NotificationsAlerts));
