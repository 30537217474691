import React from 'react';
import { withStyles } from '@ui-lib/core/styles';
import classNames from '@ui-lib/core/classNames';
import { connect } from 'react-redux';

const styles = theme => ({
    root : {
        width : '100%',
        height : '72px',
        display : 'flex',
        padding : '16px 20px',
        backgroundColor : theme.palette.grey[100],
        color : theme.palette.text.secondary,
        position : 'relative',
        alignItems : 'center',
        justifyContent : 'center'
    }
});

const ListItem = ({ classes ,i18nProvider}) => {

    return (
        <div className={classNames(classes.root)}>
            {i18nProvider.Labeli18n('end_of_notification_list')}
        </div>
    );
};

const mapStateToProps = ({ shell : { i18nProvider } }) => {
    return {
        i18nProvider,
    };
};

export default connect(mapStateToProps)(withStyles(styles)(ListItem));