export function getFormattedDate(date, i18nProvider) {
    if (!date) {
        return '';
    }
    let dateObj;
    if (typeof date === 'string') {
        dateObj = new Date(date);
    } else {
        dateObj = date;
    }
    let parseDate = `${dateObj.getUTCDate()}/${dateObj.getUTCMonth() + 1}/${dateObj.getUTCFullYear()}`;
    let currentDate = new Date();
    let currentParsedDate = `${currentDate.getUTCDate()}/${currentDate.getUTCMonth() + 1}/${currentDate.getUTCFullYear()}`;
    let finalDate = '';
    if (parseDate === currentParsedDate) {
        finalDate = i18nProvider.Texti18n('today');
    } else if (dateObj.getUTCDate() === currentDate.getUTCDate() - 1 && dateObj.getUTCMonth() === currentDate.getUTCMonth() && dateObj.getUTCFullYear() === currentDate.getUTCFullYear()) {
        finalDate = i18nProvider.Texti18n('yesterday');
    } else {
        finalDate = parseDate;
    }
    return `${finalDate} ${i18nProvider.Texti18n('date_separator')} ${dateObj.getHours()}:${dateObj.getMinutes()}`;
}

/**
 * Get the URL parameters
 * @param  {String} url The URL
 * @return {Object} The URL parameters
 */
export function getUrlParams(url) {
    const hashes = url.slice(url.indexOf('?') + 1).split('&');
    const params = {};
    hashes.map(hash => {
        const [key, val] = hash.split('=');
        params[key] = decodeURIComponent(val);
    });
    return params;
}

export function getAction(url) {
    //index of 6 because of ufe://
    return url.slice(6, url.indexOf('?'));
}

export function getTagContent(primaryAction) {
    if (primaryAction.indexOf('<a') === -1) {
        return primaryAction;
    }
    let name = primaryAction.match(/(>.*<)/g);
    if (name && name[0]) {
        name = name[0].slice(1, name[0].length - 1);
    }
    let urlStart = primaryAction.indexOf('href=\'') !== -1 ? primaryAction.indexOf('href=\'') : primaryAction.indexOf('href=\"');
    urlStart += 6;
    let urlEnd = primaryAction.indexOf('\'', urlStart) !== -1 ? primaryAction.indexOf('\'', urlStart) : primaryAction.indexOf('\"', urlStart);
    return { name : name, url : primaryAction.slice(urlStart, urlEnd) };
}

export function triggerExternalUrl(primaryAction) {
    let content = getTagContent(primaryAction);
    let url;
    if (typeof content === 'string') {
        url = content;
    } else {
        url = content.url;
    }
    window.open(url, '_blank');
}

export function triggerUfeEvent(primaryAction, setModal, closeNotificationCenter) {
    let event;
    let action;
    let params;
    let content = getTagContent(primaryAction);
    if (typeof content === 'string') {
        action = getAction(content);
        params = getUrlParams(content);
    } else {
        action = getAction(content.url);
        params = getUrlParams(content.url);
    }
    switch ( action ) {
        case 'openJourney':
            event = new CustomEvent('ufe-events-open-journey');
            event.nameModal = 'ufe-events-open-journey';
            event.data = {
                payload : {
                    journeyId : params.id,
                    args : params.args,
                }
            };
            window.dispatchEvent(event);
            closeNotificationCenter && closeNotificationCenter();
            break;
        case 'openJourneyWithContext':
            event = new CustomEvent('ufe-events-open-journey-with-context');
            event.nameModal = 'ufe-events-open-journey-with-context';
            try {
                event.data = {
                    payload : {
                        journeysToOpen : JSON.parse(params.journeysToOpen),
                        context : JSON.parse(params.context)
                    }
                };
            } catch ( e ) {
                event.data = {
                    payload : {
                        journeysToOpen : [],
                        context : {}
                    }
                };
                console.error('Notification action malformed');
            }
            setModal(true, event, closeNotificationCenter);
            break;
    }

}

export const default_cat = {
    id : '-1',
    name : 'unknown',
    icon : 'icon-notifications',
};