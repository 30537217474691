import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@ui-lib/core/styles';
import Icon from '@ui-lib/core/Icon';
import Typography from '@ui-lib/core/Typography';
import classNames from '@ui-lib/core/classNames';

const styles = theme => ({
    waringRoot : {
        width : '100%',
        backgroundColor : theme.palette.common.yellowBackground,
        height : '40px',
        padding : '10px 16px',
        display : 'flex',
        alignItems : 'center',
        marginBottom : '40px',
        marginTop: "-14px"
    },
    warningText : {
        marginLeft : '16px'
    },
    warnIcon : {
        color : theme.palette.common.yellow
    }
});

class MalformedNotification extends Component {
    render() {
        const { selectedNotification, classes, i18nProvider } = this.props;
        return (
            <div>
                <div className={classes.waringRoot}>
                    <Icon className={classNames('icon-errors-full', classes.warnIcon)}/>
                    <Typography className={classes.warningText} variant={'body2'}> {i18nProvider.Labeli18n('malformed_notification')}</Typography>
                </div>
                {selectedNotification && selectedNotification.body}
            </div>
        );
    }
}

const mapStateToProps = ({ notificationService : { NonContextualNotifications }, shell : { i18nProvider } }) => {
    return {
        selectedNotification : NonContextualNotifications.selectedNotification,
        i18nProvider
    };
};

export default connect(mapStateToProps)(withStyles(styles)(MalformedNotification));