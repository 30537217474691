import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@ui-lib/core/styles';
import { bindActionCreators } from 'redux';
import Dialog from '@ui-lib/core/Dialog';
import DialogActions from '@ui-lib/core/DialogActions';
import DialogContent from '@ui-lib/core/DialogContent';
import Typography from '@ui-lib/core/Typography';
import DialogTitle from '@ui-lib/core/DialogTitle';
import Button from '@ui-lib/core/Button';
import { setModal } from '../../actions/NonContextualNotifications';

const styles = theme => ({
    bold : {
        fontWeight : 'bold'
    }
});

function PrimaryActionModal({ isModalOpen, i18nProvider, modalEvent, journeysManifest, setModal, modalCallback, classes }) {
    if (!isModalOpen) {
        return <div/>;
    }

    const [journeysNames, setJourneysNames] = React.useState([]);
    React.useEffect(() => {
        if (modalEvent && modalEvent.data && modalEvent.data.payload && modalEvent.data.payload.journeysToOpen) {
            let journeysToOpen = [];
            modalEvent.data.payload.journeysToOpen.forEach(e => {
                let journeyToOpen = journeysManifest.find(f => f.id === e.journeyId);
                if (journeyToOpen) {
                    journeysToOpen.push(i18nProvider.Texti18n(journeyToOpen.i18NLabel));
                }
            });
            setJourneysNames(journeysToOpen);
        }
    }, [modalEvent]);

    if (journeysNames.length === 0) {
        //first render when use effect doesnt trigger
        return <div/>;
    }

    let contextName = modalEvent && modalEvent.data.payload.context ? modalEvent.data.payload.context.identifier : '';

    const handleClose = () => {
        setModal(false, undefined);
    };
    const handleAccept = () => {
        window.dispatchEvent(modalEvent);
        setModal(false, undefined);
        modalCallback && modalCallback();
    };
    return (
        <Dialog
            open={isModalOpen}
            onClose={handleClose}
            fullWidth={true}
            maxWidth={'lg'}
        >
            <DialogTitle id="draggable-dialog-title">
                {journeysNames.length > 1 ? i18nProvider.Labeli18n('about_to_open_journeys_with_context') : i18nProvider.Labeli18n('about_to_open_journey_with_context')}
            </DialogTitle>
            <DialogContent>
                <Typography variant={'body1'}>
                    {i18nProvider.Labeli18n('about_to_open_the')} {journeysNames.map((e, i) => {if (i === 0) return e; else return ', ' + e;})} {i18nProvider.Labeli18n('process_width')}
                    &nbsp;<span className={classes.bold}>{contextName}</span> {i18nProvider.Labeli18n('context')}.
                    {i18nProvider.Labeli18n('do_you_want_to_proceed')}?
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>
                    {i18nProvider.Labeli18n('dont_open_journey')}
                </Button>
                <Button onClick={handleAccept} color="primary" variant={'contained'}>
                    {journeysNames.length > 1 ? i18nProvider.Labeli18n('open_journeys_with_context') : i18nProvider.Labeli18n('open_journey_with_context')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const mapStateToProps = ({ notificationService : { NonContextualNotifications }, shell : { i18nProvider }, journeys : { journeysManifest } }) => {
    return {
        isModalOpen : NonContextualNotifications.isModalOpen,
        modalEvent : NonContextualNotifications.modalEvent,
        modalCallback : NonContextualNotifications.modalCallback,
        journeysManifest,
        i18nProvider,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators(
    { setModal },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PrimaryActionModal));