import React from 'react';
import { withStyles } from '@ui-lib/core/styles';
import Button from '@ui-lib/core/Button';
import classNames from '@ui-lib/core/classNames';
import Typography from '@ui-lib/core/Typography';
import Icon from '@ui-lib/core/Icon';
import CircularSpinner from '@ui-lib/custom-components/CircularSpinner';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import InfiniteScroll from '../../shared/InfiniteScroll';
import LoadingImage from '../../assets/illustration.svg';
import ErrorImage from '../../assets/error.svg';

const styles = theme => ({
    root : {
        width : '100%',
        height : '100%',
        display : 'flex',
        flexDirection : 'column',
        position : 'relative'
    },
    actionContainer : {
        width : '100%'
    },
    action : {
        width : '178px',
        height : '28px',
        marginTop : '16px'
    },
    actionIcon : {
        width : '16px',
        height : '16px',
        fontSize : '16px'
    },
    container : {
        width : '100%',
        top : 1, //16 padding + 28 button size + 16 padding
        left : 0,
        overflow : 'scroll',
        paddingRight : '10px',
        bottom : 0,
        position : 'absolute'
    },
    innerContainer : {
        height : 'auto',
        whiteSpace : 'nowrap',
        backgroundColor : theme.palette.background.default,
        borderLeft : '1px solid' + theme.palette.grey[400],
        borderRight : '1px solid' + theme.palette.grey[400],
        width : '100%'
    },
    topBorder : {
        backgroundColor : theme.palette.grey[400],
        height : '1px',
        position : 'absolute',
        top : 0, //Button 16 +28 +16
        left : 0,
        width : 'calc(100% - 17px)' //Padding (10px) + margin (7px) (infinite scroll lib)
    },
    bottomBorder : {
        backgroundColor : theme.palette.grey[400],
        height : '1px',
        position : 'absolute',
        top : 'calc(100% - 7px)', //margin (7px)(infinite scroll lib)
        left : 0,
        width : 'calc(100% - 17px)' //Padding (10px) + margin (7px) (infinite scroll lib)
    },
    loadingFix : {
        height : '100%'
    },
    loadingContainer : {
        width : '100%',
        height : '100%',
        display : 'flex',
        alignItems : 'center',
        justifyContent : 'center',
        flexDirection : 'column',
        padding : '10px',
    },
    title : {
        marginTop : '32px'
    },
    subtitle : {
        marginTop : '4px',
        color : theme.palette.text.primary
    }
});

const NotificationList = ({ classes, isLoadingData, centerNotifications, i18nProvider }) => {
    return (
        <div className={classes.root}>
            {/*    <div className={classes.actionContainer}>
             <Button className={classes.action} size={'small'}>
             <Typography variant={'subtitle2'}>
             <Icon className={classNames('icon-read', classes.actionIcon)}/> &nbsp; Mark all as read
             </Typography>
             </Button>
             </div>*/}
            <div className={classes.topBorder}/>
            <div className={classes.bottomBorder}/>
            <div id={'scrollableDiv'} className={classes.container}>
                <div className={classNames(classes.innerContainer, {
                    [classes.loadingFix] : isLoadingData,
                    [classes.loadingFix] : !isLoadingData && centerNotifications.length <= 0,
                    [classes.loadingFix] : centerNotifications.length < 9
                })}>
                    {isLoadingData ?
                        <div className={classes.loadingContainer}>
                            <CircularSpinner size={70}/>
                            <br/>
                            Loading...
                        </div>
                        :
                        centerNotifications.length <= 0 ?
                            <div className={classes.loadingContainer}>
                                <LoadingImage/>
                                <Typography className={classes.title} variant={'h4'}>
                                    {i18nProvider.Labeli18n('no_notification')}
                                </Typography>
                                <Typography className={classes.subtitle} variant={'caption'}>
                                    {i18nProvider.Labeli18n('up_to_date')}
                                </Typography>
                            </div>
                            :
                            <InfiniteScroll
                                selectDefault={true}
                                showSelected={true}
                                useCenterNotifications={true}
                                openCenterOnItemClick={false}
                                scrollableTarget="scrollableDiv"
                                action={true}
                                ErrorComponent={<div className={classes.loadingContainer}>
                                    <ErrorImage/>
                                    <Typography className={classes.title} variant={'h4'}>
                                        {i18nProvider.Labeli18n('error_notifications')}
                                    </Typography>
                                    <Typography className={classes.subtitle} variant={'caption'}>
                                        {i18nProvider.Labeli18n('please_try_again_later')}
                                    </Typography>
                                </div>}
                            />
                    }
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = ({ notificationService : { NonContextualNotifications }, shell : { i18nProvider } }) => {
    return {
        notifications : NonContextualNotifications.notifications,
        categories : NonContextualNotifications.categories,
        isLoadingData : NonContextualNotifications.isLoadingData,
        centerNotifications : NonContextualNotifications.centerNotifications,
        i18nProvider
    };
};

const mapDispatchToProps = dispatch => bindActionCreators(
    {},
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(NotificationList));