import React from 'react';
import ToggleIcon from './ToggleIcon';
import List from './List';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ClickAwayListener from '@ui-lib/core/ClickAwayListener';
import { setNotificationQuickActionOpen } from '../actions/NonContextualNotifications';
import PrimaryActionModal from './PrimaryActionModal';

const iframeFixStyles = {
    position : 'absolute',
    top : '106px', //Header + Subheader
    left : 0,
    zIndex : 'auto',
    width : '100%',
    height : 'calc(100vh - 106px)'
};

const NotificationQuickAction = ({ isNotificationQuickActionOpen, setNotificationQuickActionOpen, isNotificationCenterOpen }) => {
    const onClick = () => {
        if (isNotificationQuickActionOpen)
            setNotificationQuickActionOpen(false);

    };
    return (
        <div>
            {isNotificationQuickActionOpen && !isNotificationCenterOpen && <div style={iframeFixStyles}/>}
            <ClickAwayListener onClickAway={onClick}>
                <ToggleIcon>
                    {isNotificationQuickActionOpen && <List/>}
                </ToggleIcon>
            </ClickAwayListener>
            <PrimaryActionModal/>
        </div>
    );
};

const mapStateToProps = ({ notificationService : { NonContextualNotifications } }) => {
    return {
        isNotificationQuickActionOpen : NonContextualNotifications.isNotificationQuickActionOpen,
        isNotificationCenterOpen : NonContextualNotifications.isNotificationCenterOpen
    };
};

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setNotificationQuickActionOpen
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(NotificationQuickAction);