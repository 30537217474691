import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Button from '@ui-lib/core/Button';
import Typography from '@ui-lib/core/Typography';
import { withStyles } from '@ui-lib/core/styles';
import { setNotificationCenterOpen, setNotificationQuickActionOpen } from '../../../actions/NonContextualNotifications';

const styles = theme => ({
    root : {
        width : '100%',
        height : '100%',
    }
});

function Action({ classes, setNotificationCenterOpen, setNotificationQuickActionOpen, preFetchError, preFetchDone, i18nProvider }) {
    const onClick = () => {
        setNotificationCenterOpen(true);
        setNotificationQuickActionOpen(false);
    };
    return (
        <Button color={'primary'} className={classes.root} disabled={!preFetchDone || preFetchError} onClick={onClick}>
            <Typography variant={'subtitle2'}>
                {i18nProvider.Labeli18n('open_notification_center')}
            </Typography>
        </Button>
    );
}

const mapStateToProps = ({ notificationService : { NonContextualNotifications }, shell : { i18nProvider } }) => {
    return {
        preFetchDone : NonContextualNotifications.preFetchDone,
        preFetchError : NonContextualNotifications.preFetchError,
        i18nProvider,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setNotificationCenterOpen,
        setNotificationQuickActionOpen
    },
    dispatch
);

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(Action));