import React from 'react';
import { withStyles } from '@ui-lib/core/styles';
import Skeleton from '@ui-lib/custom-components/Skeleton';

const styles = theme => ({
    root : {
        width : '100%',
        height : '90px',
        display : 'flex',
        padding : '16px 20px',
        borderBottom : '1px solid ' + theme.palette.grey[400],
        position : 'relative',
    },
    iconContainer : {
        marginRight : '17px',
        width : '16px'
    },
    textContainer : {
        display : 'flex',
        flex : 1,
        flexDirection : 'column'
    },
    title : {
        flex : 1,
        marginBottom : '8px',
    },
    subtitle : {
        flex : 1
    }
});

const LoadingList = ({ classes }) => {
    return (
        <React.Fragment>
            {Array.from({ length : 4 }).map((e, i) => {
                return <div key={i} className={classes.root}>
                    <div className={classes.iconContainer}>
                        <Skeleton height={16}/>
                    </div>
                    <div className={classes.textContainer}>
                        <div className={classes.title}>
                            <Skeleton height={30}/>
                        </div>
                        <div className={classes.subtitle}>
                            <Skeleton height={20}/>
                        </div>
                    </div>
                </div>;
            })}
        </React.Fragment>
    );
};

export default withStyles(styles)(LoadingList);