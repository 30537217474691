import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@ui-lib/core/styles';
import { bindActionCreators } from 'redux';
import CircularSpinner from '@ui-lib/custom-components/CircularSpinner';
import Item from '../ListItem';
import { setCenterNotificationPage, setNotificationPage, setSelectedNotification, setCenterNotifications } from '../../actions/NonContextualNotifications';
import InfiniteScroll from 'react-infinite-scroll-component';
import NoMoreDataItem from './NoMoreDataItem';
import { default_cat } from '../utils';

const styles = () => ({
    root : {
        width : '100%',
        height : '100%',
        display : 'flex',
        alignItems : 'center',
        justifyContent : 'center',
        margin : '10px 0px',
        padding : '6px 0px'
    }
});

function LoadingComponent({ classes }) {
    return <div key={'spinnerContainer'} className={classes.root}>
        <CircularSpinner key={'spinner'}/>
    </div>;
}

const Loader = withStyles(styles)(LoadingComponent);

class CustomInfiniteScroll extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading : this.props.useCenterNotifications && this.props.selectedNotification ? !this.isNotificationPresent(this.props.centerNotifications) : false,
            isScrollable : false,
        };
    }

    static defaultProps = {
        showSelected : false,
        openCenterOnItemClick : true,
        action : false,
        scrollableTarget : 'scrollableDiv',
        useCenterNotifications : false,
        ignoreCategoryFilter : false,
        useNotifications : false,
        selectDefault : false //When loaded will select the first notification in the array
    };

    isNotificationPresent = (notificationList) => {
        if (!notificationList) {
            return false;
        }
        const { selectedNotification } = this.props;
        return notificationList.filter(e => e.id === selectedNotification.id).length > 0;
    };

    componentDidMount() {
        this._ismounted = true;
        const { isLoading } = this.state;
        if (isLoading) {
            const { notificationsPage, notifications, setCenterNotifications, setCenterNotificationPage } = this.props;
            setCenterNotifications(notifications);
            setCenterNotificationPage(notificationsPage);
        } else {
            const { showSelected, selectDefault, selectedNotification } = this.props;
            if (showSelected && selectedNotification) {
                let ref = document.getElementById('notification' + selectedNotification.id);
                if (ref) {
                    ref.scrollIntoView({
                        behavior : 'instant',
                        block : 'start',
                    });
                }
            }
            if (selectDefault && !selectedNotification) {
                const { setSelectedNotification, notifications, notificationService } = this.props;
                setSelectedNotification(notifications[0], undefined, notificationService);
            }
        }
        const { scrollableTarget } = this.props;
        let scrollableElement = document.getElementById(scrollableTarget);
        let isScrollable = !scrollableElement ? false : scrollableElement.clientHeight < scrollableElement.scrollHeight;
        if (this.state.isScrollable !== isScrollable) {
            this.setState({ isScrollable : isScrollable });
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let { selectedNotification, showSelected, scrollableTarget } = this.props;
        if (prevState.isLoading && !this.state.isLoading && selectedNotification && showSelected) {
            let ref = document.getElementById('notification' + selectedNotification.id);
            if (ref) {
                ref.scrollIntoView({
                    behavior : 'instant',
                    block : 'start',
                });
            }
        }
        if (this.state.isLoading && prevProps.centerNotifications.length < this.props.centerNotifications.length) {
            this.setState({ isLoading : false });
        }
        let scrollableElement = document.getElementById(scrollableTarget);
        let isScrollable = !scrollableElement ? false : scrollableElement.clientHeight < scrollableElement.scrollHeight;
        if (this.state.isScrollable !== isScrollable) {
            this.setState({ isScrollable : isScrollable });
        }
    }

    componentWillUnmount() {
        this._ismounted = false;
    }

    handleLoadMore = () => {
        const {
            notificationService, setCenterNotificationPage, setNotificationPage,
            selectedCategory,
            notificationsPage, centerNotificationsPage, useCenterNotifications, useNotifications,
            keywords, startDate, endDate, isReadFilter
        } = this.props;
        if (this._ismounted) {
            //TODO: Add filters to the get
            let newPage = 0;
            let filters = {};
            if (useCenterNotifications) {
                newPage = centerNotificationsPage + 1;
                setCenterNotificationPage(newPage);
                filters.categoryId = selectedCategory.name !== 'total' ? selectedCategory.id : undefined;
                filters.keywords = keywords;
                filters.startDate = startDate;
                filters.endDate = endDate;
                filters.isRead = isReadFilter;
            } else if (useNotifications) {
                newPage = notificationsPage + 1;
                setNotificationPage(newPage);
            }
            notificationService.get({ page : newPage, ...filters }, useNotifications, useCenterNotifications);
        }
    };

    getCategoryInfo = (notificationCategories) => {
        let { categories } = this.props;
        if (!notificationCategories || notificationCategories.length <= 0) {
            return default_cat;
        }
        return categories.find(e => e.id === notificationCategories[0].id);
    };

    render() {
        let {
            metadata, notifications, action, ErrorComponent, scrollableTarget,
            openCenterOnItemClick, showSelected, selectedCategory, ignoreCategoryFilter,
            useCenterNotifications, useNotifications, centerNotifications, listMetadata
        } = this.props;

        let { isLoading } = this.state;

        if (isLoading) {
            return <Loader key={'mainSpinner'}/>;
        }
        let currentMetadata;
        if (selectedCategory === 'total' || ignoreCategoryFilter) {
            currentMetadata = metadata.find(e => e.name === 'total');
        } else if (selectedCategory === '-1') {
            let unknown = metadata.find(e => e.name === 'unknown');
            currentMetadata = unknown ? unknown : {
                id : 0,
                name : 'unknown',
                total : 0,
                unread : 0
            };
        } else {
            currentMetadata = metadata.find(e => e.id === selectedCategory);
        }
        let currentListMetadata = listMetadata.find(e => e.name === 'total');
        if (!currentMetadata || !currentListMetadata) {
            console.log('Malformed metadata');
            return ErrorComponent;
        }
        let hasMore = useNotifications ? currentListMetadata.total > notifications.length : useCenterNotifications ? currentMetadata.total > centerNotifications.length : false;
        let dataLength = useNotifications ? notifications.length : useCenterNotifications ? centerNotifications.length : 0;

        return <InfiniteScroll
            next={this.handleLoadMore}
            hasMore={hasMore}
            loader={<Loader key={'mainSpinner'}/>}
            dataLength={dataLength}
            scrollableTarget={scrollableTarget}
        >
            {useNotifications && notifications.map((e, i) => <Item key={i + 1}
                                                                   openCenter={openCenterOnItemClick}
                                                                   showSelected={showSelected}
                                                                   category={this.getCategoryInfo(e.categories)}
                                                                   notification={e}
                                                                   action={action}
            />)}
            {useCenterNotifications && centerNotifications.map((e, i) => <Item key={i + 1}
                                                                               openCenter={openCenterOnItemClick}
                                                                               showSelected={showSelected}
                                                                               category={this.getCategoryInfo(e.categories)}
                                                                               notification={e}
                                                                               action={action}
            />)}
            {this.state.isScrollable && !hasMore && <NoMoreDataItem/>}
        </InfiniteScroll>;
    }
}

const mapStateToProps = ({ shell : { services }, notificationService : { NonContextualNotifications } }) => {
    return {
        notifications : NonContextualNotifications.notifications,
        centerNotifications : NonContextualNotifications.centerNotifications,
        metadata : NonContextualNotifications.metadata,
        listMetadata : NonContextualNotifications.listMetadata,
        categories : NonContextualNotifications.categories,
        selectedNotification : NonContextualNotifications.selectedNotification,
        selectedCategory : NonContextualNotifications.selectedCategory,
        notificationService : services.Notifications,
        notificationsPage : NonContextualNotifications.notificationsPage,
        centerNotificationsPage : NonContextualNotifications.centerNotificationsPage,
        keywords : NonContextualNotifications.keywords,
        startDate : NonContextualNotifications.startDate,
        endDate : NonContextualNotifications.endDate,
        isReadFilter : NonContextualNotifications.isReadFilter,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setCenterNotificationPage,
        setNotificationPage,
        setSelectedNotification,
        setCenterNotifications
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(CustomInfiniteScroll);