import React from 'react';
import { withStyles } from '@ui-lib/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ErrorBoundary from './AlternativeBody/ErrorBoundary';
import AlternativeBody from './AlternativeBody';
import { setNotificationBadgeContent } from '../../../actions/NonContextualNotifications';
import LoadingBody from './LoadingBody';

const styles = theme => ({
    root : {
        padding : '40px'
    },
    empty : {
        color : theme.palette.text.secondary,
        display : 'flex',
        alignItems : 'center',
        justifyContent : 'center'
    }
});

const Body = ({ selectedNotification, classes, notificationBadgeContent, setNotificationBadgeContent, isLoadingData, centerNotifications }) => {

    React.useEffect(() => {
        if (selectedNotification) {
            let isNewNotification = notificationBadgeContent.find(e => e.id === selectedNotification.id);
            if (isNewNotification) {
                setNotificationBadgeContent(notificationBadgeContent.filter(e => e.id !== selectedNotification.id));
            }
        }
    }, [selectedNotification]);

    const getNotificationBody = (isLoadingData) => {
        if (isLoadingData) {
            return <LoadingBody/>;
        }
        if (selectedNotification.specialBody) {
            return <ErrorBoundary>
                <AlternativeBody alternativeBody={selectedNotification.specialBody}/>
            </ErrorBoundary>;
        } else if (selectedNotification.body) {
            return selectedNotification.body;
        } else {
            return <div/>;
        }
    };

    return (
        <div className={classes.root}>
            {!isLoadingData && !selectedNotification ?
                <div className={classes.empty}>
                    The notification detail will appear here
                </div>
                :
                getNotificationBody(isLoadingData)
            }
        </div>
    );
};

const mapStateToProps = ({ notificationService : { NonContextualNotifications } }) => {
    return {
        selectedNotification : NonContextualNotifications.selectedNotification,
        notificationBadgeContent : NonContextualNotifications.notificationBadgeContent,
        isLoadingData : NonContextualNotifications.isLoadingData,
        centerNotifications : NonContextualNotifications.centerNotifications,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setNotificationBadgeContent
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Body));