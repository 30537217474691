import React from 'react';
import Skeleton from '@ui-lib/custom-components/Skeleton';

const LoadingBody = () => {
    return (
        <div>
            <Skeleton width={'80%'} height={12}/>
            <br/>
            <br/>
            <Skeleton width={'50%'} height={12}/>
            <br/>
            <br/>
            <Skeleton width={'20%'} height={12}/>
            <br/>
            <br/>
            <Skeleton width={'65%'} height={12}/>
        </div>
    );
};

export default LoadingBody;