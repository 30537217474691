import React from 'react';
import MalformedNotification from './MalformedNotification';

class ErrorBoundary extends React.Component {

    state = {
        hasError : false
    };

    static getDerivedStateFromError(error) {
        return { hasError : true };
    }

    componentDidCatch(error, errorInfo) {
        console.error(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            return <MalformedNotification/>;
        }
        return this.props.children;
    }
}

export default ErrorBoundary;