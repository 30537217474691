import React from 'react';
import { withStyles } from '@ui-lib/core/styles';
import {
    GenericCardLayout
} from '@ui-lib/custom-components/Carousel';
import Icon from '@ui-lib/core/Icon';
import classNames from '@ui-lib/core/classNames';
import { connect } from 'react-redux';
import Skeleton from '@ui-lib/custom-components/Skeleton';

const customCardStyles = theme => ({
    divider : {
        width : '2px',
        height : '32px',
        backgroundColor : theme.palette.primary.main,
        marginLeft : '15px',
        marginRight : '15px',
        marginTop : '24px',
        borderRadius : '15px'
    },
    root : {
        padding : '16px 12px',
        minWidth : '188px',
        border : '1px solid ' + theme.palette.grey[400],
        minHeight : '80px',
        maxWidth : '188px',
        maxHeight : '80px',
        display : 'flex',
        overflow : 'hidden',
        fontFamily : ' "Bariol-Regular"',
    },
    selected : {
        backgroundColor : theme.palette.primary.light,
        border : '1px solid ' + theme.palette.primary.main
    },
    cardRoot : {
        boxShadow : 'none !important'
    },
    leftContainer : {
        marginRight : '4px',
    },
    mainCount : {
        width : '80px',
        height : '34px',
        display : 'flex',
        alignItems : 'center',
        justifyContent : 'center',
        fontSize : '34px',
    },
    mainCountSelected : {
        color : theme.palette.primary.main
    },
    subCount : {
        width : '80px',
        height : '15px',
        display : 'flex',
        alignItems : 'center',
        justifyContent : 'center',
        fontSize : '12px',
        color : theme.palette.text.secondary,
        fontFamily : ' "SourceSansPro-Regular"',
    },
    subCountSelected : {
        color : theme.palette.text.primary
    },
    iconContainer : {
        width : '80px',
        display : 'flex',
        alignItems : 'center',
        justifyContent : 'center',
    },
    rightContainer : {
        flexDirection : 'column',
        display : 'flex',
        alignItems : 'center',
        justifyContent : 'center'
    },
    modeContainer : {
        width : '80px',
        display : 'flex',
        alignItems : 'center',
        justifyContent : 'center',
        height : '16px',
        fontSize : '16px',
        marginTop : '8px',
    },
    loadingColor : {
        color : theme.palette.text.disabled
    },
    iconSuccess : {
        color : theme.palette.common.green,
        '&:before' : {
            color : theme.palette.common.green + ' !important',
        }
    },
    iconWarning : {
        color : theme.palette.common.yellow
    },
    iconInfo : {
        color : theme.palette.common.blue,
        '&:before' : {
            backgroundColor : theme.palette.common.white,
            borderRadius : '50%'
        }
    },
    iconError : {
        color : theme.palette.error.main
    },
});

//Selected is a reserved keyword for the carousel
const NotificationCard = ({ category = {}, metadata = {}, pressed : selected, handleClick, isHeader = false, classes, i18nProvider, isLoadingMetadata }) => {
    return <div style={{ display : 'inline-flex' }}>
        <GenericCardLayout
            fullHeight={true}
            onClick={() => {
                handleClick && handleClick(category);
            }}
            classes={{ cardRoot : classes.cardRoot }}
        >
            <div className={classNames(classes.root, {
                [classes.selected] : selected
            })}>
                <div className={classes.leftContainer}>
                    <div className={classNames(classes.mainCount, {
                        [classes.mainCountSelected] : selected,
                        [classes.loadingColor] : isLoadingMetadata
                    })}>
                        {isLoadingMetadata ? 0 : metadata.total}
                    </div>
                    <div className={classNames(classes.subCount, {
                        [classes.subCountSelected] : selected
                    })}>
                        {isLoadingMetadata ? <Skeleton height={'8px'} width={'60px'}/> : <>{metadata.unread} &nbsp;{i18nProvider.Labeli18n('unread')}</>}
                    </div>
                </div>
                <div className={classes.rightContainer}>
                    {!isLoadingMetadata && category.icon && <div className={classes.iconContainer}>
                        <Icon className={classNames(category.icon, classes.icon, {
                            [classes.iconSuccess] : category.name.toUpperCase() === 'SUCCESS',
                            [classes.iconInfo] : category.name.toUpperCase() === 'INFO',
                            [classes.iconWarning] : category.name.toUpperCase() === 'WARNING',
                            [classes.iconError] : category.name.toUpperCase() === 'ERROR'
                        })}/>
                    </div>}
                    <div className={classNames(classes.modeContainer, {
                        [classes.loadingColor] : isLoadingMetadata
                    })}>
                        {i18nProvider.Labeli18n(category.name)}
                    </div>
                </div>
            </div>
        </GenericCardLayout>
        {isHeader && <div className={classes.divider}/>}
    </div>;
};

const mapStateToProps = ({ shell : { i18nProvider }, notificationService : { NonContextualNotifications } }) => {
    return {
        i18nProvider,
        isLoadingMetadata : NonContextualNotifications.isLoadingMetadata
    };
};

export default connect(mapStateToProps)(withStyles(customCardStyles)(NotificationCard));