(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("ReactRedux"), require("Redux"));
	else if(typeof define === 'function' && define.amd)
		define(["React", "ReactRedux", "Redux"], factory);
	else if(typeof exports === 'object')
		exports["NotificationsUi"] = factory(require("React"), require("ReactRedux"), require("Redux"));
	else
		root["NotificationsUi"] = factory(root["React"], root["ReactRedux"], root["Redux"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__3__) {
return 