import React from 'react';
import Icon from '@ui-lib/core/Icon';
import Badge from '@ui-lib/core/Badge';
import classNames from '@ui-lib/core/classNames';
import IconButton from '@ui-lib/core/IconButton';
import { withStyles } from '@ui-lib/core/styles';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setNotificationQuickActionOpen, setNotificationCenterOpen, clearFilters } from '../../actions/NonContextualNotifications';

const styles = theme => ({
    root : {
        color : theme.palette.common.white,
        position : 'relative',
        boxSizing : 'border-box',
        zIndex : 10
    },
    rootSelected : {
        color : theme.palette.common.black,
        backgroundColor : theme.palette.grey[100],
        '&:after' : {
            zIndex : '-10',
            content : '""',
            position : 'absolute',
            width : '0',
            height : '0',
            marginLeft : '0',
            bottom : '0',
            top : 'calc(100% + 8px)',
            left : 'calc(50% + 8px)',
            boxSizing : 'border-box',
            border : '5px solid #fff',
            borderColor : 'transparent transparent #fff #fff',
            transformOrigin : '0 0',
            transform : 'rotate(135deg)',
            boxShadow : '0 2px 4px 0 rgba(0, 0, 0, 0.29)'
        },
        '&:before' : {
            zIndex : '10',
            content : '""',
            position : 'absolute',
            width : '0',
            height : '0',
            marginLeft : '0',
            bottom : '0',
            top : 'calc(100% + 8px)',
            left : 'calc(50% + 8px)',
            boxSizing : 'border-box',
            border : '5px solid black',
            borderColor : 'transparent transparent #fff #fff',
            transformOrigin : '0 0',
            transform : 'rotate(135deg)'
        }
    },
    rootToggled : {
        color : theme.palette.common.black,
        backgroundColor : theme.palette.grey[100],
        borderTopLeftRadius : '6px',
        borderTopRightRadius : '6px',
        zIndex : 1599,
    },
    button : {
        '&:hover' : {
            backgroundColor : theme.palette.type === 'light' ? `rgba(255, 255, 255, 0.2)` : `rgba(255, 255, 255, ${theme.palette.action.hoverOpacity})`
        }
    },
    icon : {
        width : '20px',
        height : '20px',
        fontSize : '20px'
    },
    badge : {
        backgroundColor : theme.palette.error.main,
        color : theme.palette.common.white,
        minWidth : 'unset',
        height : '16px',
        width : '16px',
        fontSize : '11px'
    },
    whiteBottomBorder : {
        position : 'absolute',
        width : '44px',
        height : '5px',
        backgroundColor : theme.palette.grey[100],
        top : '100%',
        left : 0,
    }
});

const ToggleIcon = ({
                        classes, setNotificationQuickActionOpen, isNotificationQuickActionOpen, isNotificationCenterOpen,
                        setNotificationCenterOpen, children, notificationBadgeContent, clearFilters, isPrivate
                    }) => {
    let badgeContent = notificationBadgeContent.length;
    const onClick = () => {
        if (isNotificationCenterOpen) {
            //x:Close Notification center
            setNotificationCenterOpen(false);
            clearFilters();
            return;
        }
        setNotificationQuickActionOpen(!isNotificationQuickActionOpen);
    };
    return (
        <div className={classNames(classes.root, {
            [classes.rootSelected] : isNotificationQuickActionOpen,
            [classes.rootToggled] : isNotificationCenterOpen
        })}>
            <IconButton className={classes.button} onClick={onClick}>
                <Badge invisible={!badgeContent || isNotificationCenterOpen || isPrivate} badgeContent={badgeContent} max={99} classes={{ badge : classes.badge }}>
                    <Icon className={classNames({
                        'icon-notifications' : !isNotificationCenterOpen,
                        'icon-close' : isNotificationCenterOpen
                    }, classes.icon)}/>
                </Badge>
            </IconButton>
            {children}
            {isNotificationCenterOpen && <div className={classes.whiteBottomBorder}/>}
        </div>
    );
};

const mapStateToProps = ({ notificationService : { NonContextualNotifications }, shell : { isPrivate } }) => {
    return {
        isNotificationQuickActionOpen : NonContextualNotifications.isNotificationQuickActionOpen,
        isNotificationCenterOpen : NonContextualNotifications.isNotificationCenterOpen,
        notificationBadgeContent : NonContextualNotifications.notificationBadgeContent,
        isPrivate
    };
};

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setNotificationQuickActionOpen,
        setNotificationCenterOpen,
        clearFilters
    },
    dispatch
);

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(ToggleIcon));