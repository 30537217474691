import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withStyles } from '@ui-lib/core/styles';
import { setSelectedCategory, setIsLoadingData, setCenterNotificationPage, setSelectedNotification } from '../../actions/NonContextualNotifications';
import { CarouselSlider } from '@ui-lib/custom-components/Carousel';
import NotificationCard from './NotificationCard';

const styles = theme => ({
    root : {
        marginTop : '16px'
    },
    carousalRoot : {
        marginLeft : 0
    }
});

const Carousel = (
    {
        classes, categories, setSelectedCategory,
        selectedCategory, metadata, setIsLoadingData, setSelectedNotification,
        centerNotificationsPage, setCenterNotificationPage, notificationService, isReadFilter, endDate, startDate, keywords
    }
) => {

    if (!categories || !metadata) {
        //Should never enter here, it should be preload before
        return <div>loading...</div>;
    }

    const getNewData = (categoryId) => {
        //TODO: set default selected notification
        setIsLoadingData(true);
        setCenterNotificationPage(1);
        setSelectedNotification(undefined);
        // noinspection JSCheckFunctionSignatures
        notificationService.get({
            page : 1, categoryId,
            keywords : keywords,
            startDate : startDate,
            endDate : endDate,
            isRead : isReadFilter
        }, false, true, true);
    };

    const handleClickHeader = () => {
        setSelectedCategory('total');
        getNewData();
    };

    const handleClick = (category) => {
        if (category.id === selectedCategory) {
            handleClickHeader();
            return;
        }
        setSelectedCategory(category.id);
        getNewData(category.id);
    };

    const getMetadataInfo = (categoryId) => {
        if (categoryId === 'total') {
            return metadata.find(e => e.name === categoryId);
        } else if (categoryId === "-1") {
            let unknown = metadata.find(e => e.name === 'unknown');
            return unknown ? unknown : {
                id : 0,
                name : 'unknown',
                total : 0,
                unread : 0
            };
        } else {
            return metadata.find(e => e.id === categoryId);
        }
    };

    return (
        <div className={classes.root}>
            <CarouselSlider
                isLTR={false}
                circularSlider={false}
                onlyHoverNavigation={false}
                interval={2000}
                singleSelection={true}
                classes={{ carousalRoot : classes.carousalRoot }}
                renderHeader={() => (
                    <NotificationCard
                        isHeader={true}
                        handleClick={handleClickHeader}
                        metadata={getMetadataInfo('total')}
                        category={{ name : 'notifications', id : 'total' }}
                        pressed={selectedCategory === 'total'}
                    />
                )}
            >
                {categories.map((category, index) => (
                    <NotificationCard
                        handleClick={handleClick}
                        key={index}
                        category={category}
                        metadata={getMetadataInfo(category.id)}
                        pressed={selectedCategory === category.id}
                    />
                ))}
            </CarouselSlider>
        </div>
    );
};

const mapStateToProps = ({ shell : { services }, notificationService : { NonContextualNotifications } }) => {
    return {
        categories : NonContextualNotifications.categories,
        keywords : NonContextualNotifications.keywords,
        startDate : NonContextualNotifications.startDate,
        endDate : NonContextualNotifications.endDate,
        isReadFilter : NonContextualNotifications.isReadFilter,
        selectedCategory : NonContextualNotifications.selectedCategory,
        metadata : NonContextualNotifications.metadata,
        notificationService : services.Notifications,
        centerNotificationsPage : NonContextualNotifications.centerNotificationsPage,
    };
};

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setSelectedCategory,
        setCenterNotificationPage,
        setSelectedNotification,
        setIsLoadingData
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Carousel));