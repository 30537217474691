import React from 'react';
import { withStyles } from '@ui-lib/core/styles';
import { fade } from '@ui-lib/core/styles/colorManipulator';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { clearFilters, setNotificationCenterOpen } from '../actions/NonContextualNotifications';
import TitleBar from './TitleBar';
import Filters from './Filters';
import Carousel from './Carousel';
import NotificationList from './NotificationList';
import NotificationContent from './NotificationContent';

const styles = theme => ({
    root : {
        position : 'absolute',
        top : '46px',
        left : '0',
        width : '100%',
        height : 'calc(100% - 70px)',
        backgroundColor : theme.palette.grey[100],
        zIndex : 1400,
        padding : '20px',
        paddingBottom : '0px',
        display : 'flex',
        flexDirection : 'column'
    },
    overlay : {
        position : 'absolute',
        top : 0,
        left : 0,
        width : '100%',
        height : '46px',
        zIndex : 1500,
        backgroundColor : fade(theme.palette.common.black, 0.7)
    },
    listsContainer : {
        marginTop : '40px',
        display : 'flex',
        height : '100%'
    },
    leftList : {
        width : '35%',
        maxWidth : '528px', //508 base space + 20 padding
        height : '100%',
        marginRight : '10px'
    },
    rightList : {
        flex : 1,
        height : '100%'
    }
});

const NotificationCenter = ({ classes, isNotificationCenterOpen, setNotificationCenterOpen, clearFilters }) => {
    const onOverlayClick = () => {
        //x:Close Notification center
        setNotificationCenterOpen(false);
        clearFilters();
    };
    return (
        <React.Fragment>
            {isNotificationCenterOpen && <React.Fragment>
                <div className={classes.overlay} onClick={onOverlayClick}/>
                <div className={classes.root}>
                    <TitleBar/>
                    <Filters/>
                    <Carousel/>
                    <div className={classes.listsContainer}>
                        <div className={classes.leftList}>
                            <NotificationList/>
                        </div>
                        <div className={classes.rightList}>
                            <NotificationContent/>
                        </div>
                    </div>
                </div>
            </React.Fragment>}
        </React.Fragment>
    );
};

const mapStateToProps = ({ notificationService : { NonContextualNotifications } }) => {
    return {
        isNotificationCenterOpen : NonContextualNotifications.isNotificationCenterOpen
    };
};

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setNotificationCenterOpen,
        clearFilters
    },
    dispatch
);

export default withStyles(styles, { withName : 'NotificationCenter' })(connect(mapStateToProps, mapDispatchToProps)(NotificationCenter));