import React from 'react';
import { withStyles } from '@ui-lib/core/styles';
import Action from './Action';
import ListContent from './ListComponent';

const styles = theme => ({
    root: {
        position: 'absolute',
        top: '51px',
        right: 0,
        backgroundColor: theme.palette.grey[50],
        borderRadius: '6px',
        width: '383px',
        border: '1px solid ' + theme.palette.grey[400],
        boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.29)',
        "-webkit-box-orient": 'vertical',
        "-webkit-box-direction": 'normal',
        "-ms-flex-direction": 'column',
        flexDirection: 'column',
        display: 'flex',
        fallbacks: [
            { display: '-webkit-box' },
            { display: '-ms-flexbox' },
        ],
        maxHeight: '460px'
    },
    content: {
        '-webkit-box-flex': "1 1 auto",
        '-ms-flex': "1 1 auto",
        flex: "1 1 auto",
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        '&::-webkit-scrollbar-track': {
            background: theme.palette.grey[200],
        },
        maxHeight: '412px'
    },
    action: {
        borderTop: '1px solid ' + theme.palette.grey[400],
        height: '48px'
    }
});

class List extends React.Component {
    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <div id="scrollableDiv" className={classes.content}>
                    <ListContent />
                </div>
                <div className={classes.action}>
                    <Action />
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(List);
