import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    clearFilters, setKeywordsFilter, setStartDateFilter, setEndDateFilter, setReadFilter, setIsLoadingData, setIsLoadingMetadata, setCenterNotificationPage, setSelectedNotification
} from '../../../actions/NonContextualNotifications';
import { withStyles } from '@ui-lib/core/styles';
import Typography from '@ui-lib/core/Typography';
import classNames from '@ui-lib/core/classNames';
import Icon from '@ui-lib/core/Icon';
import Button from '@ui-lib/core/Button';
import Chip from '@ui-lib/core/Chip';
import { defaultFilters } from '../index';
import { getFormattedDate } from '../../../shared/utils';

const styles = theme => ({
    appliedFiltersRoot : {
        marginBottom : '16px',
        marginTop : '16px',
        display : 'flex',
        alignItems : 'center',
    },
    appliedFiltersRootSpacing : {
        marginTop : '32px',
    },
    appliedFilterSpacing : {
        marginLeft : '16px'
    },
    appliedFilterChips : {
        backgroundColor : theme.palette.background.default,
        height : '24px',
        fontSize : '12px !important'
    },
    appliedFilterChipsIcon : {
        fontSize : '10px',
        width : '10px',
        height : '10px',
        color : theme.palette.text.primary,
        marginLeft : '1px',
        marginRight : '8px'
    }
});

function AppliedFilters(
    {
        classes, keywords, startDate,
        endDate, isReadFilter, clearFilters,
        setReadFilter, setEndDateFilter, setStartDateFilter,
        setKeywordsFilter, isNotificationCenterFiltersOpen, state, setState, i18nProvider,
        notificationService, selectedCategory,
        setIsLoadingData, setIsLoadingMetadata, setCenterNotificationPage, setSelectedNotification
    }
) {

    if (!keywords && !startDate && !endDate && !isReadFilter) {
        // noinspection JSConstructorReturnsPrimitive
        return null;
    }

    const onDelete = name => event => {
        switch ( name ) {
            case 'keywords':
                if (state.keywords === keywords)
                    setState({ ...state, keywords : defaultFilters.keywords });
                setKeywordsFilter(undefined);
                resetOneFilter(name);
                break;
            case 'startDate':
                if (state.startDate === startDate)
                    setState({ ...state, startDate : defaultFilters.startDate });
                setStartDateFilter(undefined);
                resetOneFilter(name);
                break;
            case 'endDate':
                if (state.endDate === endDate)
                    setState({ ...state, endDate : defaultFilters.endDate });
                setEndDateFilter(undefined);
                resetOneFilter(name);
                break;
            case 'isReadFilter':
                if (state.isReadFilter === isReadFilter)
                    setState({ ...state, isReadFilter : defaultFilters.isReadFilter });
                setReadFilter(undefined);
                resetOneFilter(name);
                break;
            default:
                break;
        }
    };

    const resetOneFilter = (filterToClear) => {
        setIsLoadingData(true);
        setIsLoadingMetadata(true);
        setCenterNotificationPage(1);
        setSelectedNotification(undefined);
        let metaFilter = {
            keyword : filterToClear === 'keywords' ? undefined : keywords,
            beginDate : filterToClear === 'startDate' ? undefined : startDate,
            endDate : filterToClear === 'endDate' ? undefined : endDate,
            status : filterToClear === 'isReadFilter' ? undefined : isReadFilter
        };
        let notificationFilter = {
            page : 1,
            categoryId : selectedCategory.name !== 'total' ? selectedCategory.id : undefined,
            keywords : filterToClear === 'keywords' ? undefined : keywords,
            startDate : filterToClear === 'startDate' ? undefined : startDate,
            endDate : filterToClear === 'endDate' ? undefined : endDate,
            isRead : filterToClear === 'isReadFilter' ? undefined : isReadFilter
        };
        // noinspection JSCheckFunctionSignatures
        notificationService.getMetadata(metaFilter, true).then(() => {
            // noinspection JSCheckFunctionSignatures
            notificationService.get(notificationFilter, false, true, true);
        }).catch(() => {});
    };

    const clearFiltersRequest = () => {
        setIsLoadingData(true);
        setIsLoadingMetadata(true);
        setCenterNotificationPage(1);
        setSelectedNotification(undefined);
        // noinspection JSCheckFunctionSignatures
        notificationService.getMetadata(undefined, true).then(() => {
            // noinspection JSCheckFunctionSignatures
            notificationService.get({
                page : 1,
            }, false, true, true);
        }).catch(() => {});
    };

    const onClearFilters = () => {
        clearFilters();
        setState({ ...state, ...defaultFilters });
        clearFiltersRequest();
    };

    return <div className={classNames(classes.appliedFiltersRoot, {
        [classes.appliedFiltersRootSpacing] : isNotificationCenterFiltersOpen
    })}>
        <Typography variant={'subtitle2'}>{i18nProvider.Labeli18n('applied_filters')}</Typography>
        <Button color={'primary'} className={classes.appliedFilterSpacing} onClick={onClearFilters}> {i18nProvider.Labeli18n('clear_all')}</Button>
        {keywords && <Chip
            variant={'outlined'}
            className={classNames(classes.appliedFilterSpacing, classes.appliedFilterChips)}
            label={i18nProvider.Texti18n('keyword_chip') + ': ' + keywords}
            onDelete={onDelete('keywords')}
            deleteIcon={<Icon className={classNames('icon-close', classes.appliedFilterChipsIcon)}/>}
        />}
        {startDate && <Chip
            variant={'outlined'}
            className={classNames(classes.appliedFilterSpacing, classes.appliedFilterChips)}
            label={i18nProvider.Texti18n('start_date_chip') + ': ' + getFormattedDate(startDate, i18nProvider)}
            onDelete={onDelete('startDate')}
            deleteIcon={<Icon className={classNames('icon-close', classes.appliedFilterChipsIcon)}/>}
        />}
        {endDate && <Chip
            variant={'outlined'}
            className={classNames(classes.appliedFilterSpacing, classes.appliedFilterChips)}
            label={i18nProvider.Texti18n('end_date_chip') + ': ' + getFormattedDate(endDate, i18nProvider)}
            onDelete={onDelete('endDate')}
            deleteIcon={<Icon className={classNames('icon-close', classes.appliedFilterChipsIcon)}/>}
        />}
        {isReadFilter && <Chip
            variant={'outlined'}
            className={classNames(classes.appliedFilterSpacing, classes.appliedFilterChips)}
            label={i18nProvider.Texti18n('read_chip') + ': ' + isReadFilter}
            onDelete={onDelete('isReadFilter')}
            deleteIcon={<Icon className={classNames('icon-close', classes.appliedFilterChipsIcon)}/>}
        />}
    </div>;
}

const mapStateToProps = ({ notificationService : { NonContextualNotifications }, shell : { i18nProvider, services } }) => {
    return {
        isNotificationCenterFiltersOpen : NonContextualNotifications.isNotificationCenterFiltersOpen,
        keywords : NonContextualNotifications.keywords,
        startDate : NonContextualNotifications.startDate,
        endDate : NonContextualNotifications.endDate,
        isReadFilter : NonContextualNotifications.isReadFilter,
        selectedCategory : NonContextualNotifications.selectedCategory,
        i18nProvider : i18nProvider,
        lang : i18nProvider.currentLanguage,
        notificationService : services.Notifications
    };
};

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        clearFilters,
        setReadFilter,
        setEndDateFilter,
        setStartDateFilter,
        setKeywordsFilter,
        setIsLoadingData,
        setIsLoadingMetadata,
        setCenterNotificationPage,
        setSelectedNotification
    },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AppliedFilters));