import React from 'react';
import { withStyles } from '@ui-lib/core/styles';
import Typography from '@ui-lib/core/Typography';
import Icon from '@ui-lib/core/Icon';
import IconButton from '@ui-lib/core/IconButton';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { setNotificationCenterFilterOpen, setNotificationQuickActionOpen } from '../../actions/NonContextualNotifications';

const styles = theme => ({
    root : {
        display : 'flex',
        marginBottom : '16px'
    },
    title : {
        flex : 1,
        display : 'flex'
    },
    buttonContainer : {
        display : 'flex'
    },
    appliedFilter : {
        backgroundColor : theme.palette.grey[100],
        borderRadius : '50%',
        width : '9px',
        height : '9px',
        position : 'absolute',
        top : '-1px',
        right : '0px'
    },
    appliedFilterMain : {
        backgroundColor : theme.palette.primary.main,
        borderRadius : '50%',
        width : '8px',
        height : '8px',
        position : 'absolute',
        top : '-1px',
        right : '0px',
        zIndex : 1
    },
    fix : {
        position : 'relative'
    },

});

const TitleBar = ({ classes, isNotificationCenterFiltersOpen, setNotificationCenterFilterOpen, i18nProvider, keywords, startDate, endDate, isReadFilter }) => {
    const handleOnClick = () => {
        setNotificationCenterFilterOpen(!isNotificationCenterFiltersOpen);
    };

    const appliedFilters = keywords || startDate || endDate || isReadFilter;

    return (
        <div className={classes.root}>
            <div className={classes.title}>
                <Typography variant={'h3'}>
                    {i18nProvider.Labeli18n('notification_centre')}
                </Typography>
            </div>
            <div className={classes.buttonContainer}>
                <IconButton onClick={handleOnClick} classes={{ label : classes.fix }} color={isNotificationCenterFiltersOpen ? 'primary' : 'default'}>
                    <Icon className={'icon-filter'}/>
                    {appliedFilters && <>
                        <div className={classes.appliedFilter}/>
                        <div className={classes.appliedFilterMain}/>
                    </>}
                </IconButton>
            </div>
        </div>
    );
};

const mapStateToProps = ({ notificationService : { NonContextualNotifications }, shell : { i18nProvider } }) => {
    return {
        isNotificationCenterFiltersOpen : NonContextualNotifications.isNotificationCenterFiltersOpen,
        keywords  : NonContextualNotifications.keywords,
        startDate  : NonContextualNotifications.startDate,
        endDate  : NonContextualNotifications.endDate,
        isReadFilter  : NonContextualNotifications.isReadFilter,
        i18nProvider
    };
};

const mapDispatchToProps = dispatch => bindActionCreators(
    {
        setNotificationCenterFilterOpen
    },
    dispatch
);

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(TitleBar));