import React from 'react';
import Link from '@ui-lib/core/Link';
import { fade } from '@ui-lib/core/styles/colorManipulator';
import { withStyles } from '@ui-lib/core/styles';
import { default as Snackbar } from '@ui-lib/custom-components/Snackbar';
import SnackbarContentStyles from '@ui-lib/custom-components/Snackbar/styles';
import Icon from '@ui-lib/core/Icon';
import IconButton from '@ui-lib/core/IconButton';
import classNames from '@ui-lib/core/classNames';
import { removeNotificationSnackbar, setNotificationCenterOpen } from '../actions/NonContextualNotifications';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

const styles = theme => {
    let defaultStyles = SnackbarContentStyles(theme);
    return {
        root : {
            ...defaultStyles.root,
            flexDirection : 'column',
            backgroundColor : fade(theme.palette.primary.main, 0.08),
        },
        backgroundFix : {
            backgroundColor : '#fff'
        },
        innerContainer : {
            display : 'flex',
            paddingRight : '16px'
        },
        textContainer : {
            flex : 1,
            display : 'flex',
            alignItems : 'center',
        },
        iconContainer : {
            width : '52px',
            height : '44px',
            minWidth : '52px',
            minHeight : '44px',
            marginRight : '8px',
            paddingLeft : '20px',
            display : 'flex',
            alignItems : 'center',
            flex : 0,
            backgroundColor : theme.palette.primary.main,
            borderBottomRightRadius : '50%',
            borderTopRightRadius : '50%',
        },
        icon : {
            fontSize : '20px',
            width : '20px',
            height : '20px',
            color : theme.palette.common.white
        },
        closeIconContainer : {
            display : 'flex',
            alignItems : 'center',
        },
        closeIconButton : {
            padding : '8px'
        },
        closeIcon : {
            fontSize : '16px',
            width : '16px',
            height : '16px',
            cursor : 'pointer'
        },
        actionContainer : {
            height : '100%',
            display : 'flex',
            alignItems : 'flex-end',
            justifyContent : 'flex-end'
        },
        link : {
            paddingRight : '16px',
            paddingBottom : '10px'
        }
    };
};

const SnackbarAggregator = ({ classes, removeNotificationSnackbar, notificationSnackbar, i18nProvider, setNotificationCenterOpen, isPrivate }) => {

    const handleClose = (event, reason) => {
        if (reason === 'clickaway')
            return;
        removeNotificationSnackbar();
    };

    const onOpenClick = () => {
        setNotificationCenterOpen(true);
        removeNotificationSnackbar();
    };
    if (isPrivate) {
        return null;
    }
    return (
        <Snackbar.default
            open={true}
            onClose={handleClose}
        >
            <div className={classes.backgroundFix}>
                <div className={classes.root}>
                    <div className={classes.innerContainer}>
                        <div className={classes.iconContainer}>
                            <Icon className={classNames('icon-notifications-full', classes.icon)}/>
                        </div>
                        <div className={classes.textContainer}>
                            {i18nProvider.Texti18n('new_notifications_snack').replace('&{1}', notificationSnackbar.length)}
                        </div>
                        <div className={classes.closeIconContainer}>
                            <IconButton onClick={handleClose} className={classes.closeIconButton}>
                                <Icon className={classNames('icon-close', classes.closeIcon)}/>
                            </IconButton>
                        </div>
                    </div>
                    <div className={classes.actionContainer}>
                        <Link className={classes.link} onClick={onOpenClick} color={'primary'}>{i18nProvider.Labeli18n('open_notification_center')}</Link>
                    </div>
                </div>
            </div>
        </Snackbar.default>
    );
};

const mapStateToProps = ({ notificationService : { NonContextualNotifications }, shell : { i18nProvider, isPrivate } }) => {
    return {
        notificationSnackbar : NonContextualNotifications.notificationSnackbar,
        i18nProvider : i18nProvider,
        isPrivate,
        lang : i18nProvider.currentLanguage // NEEDS TO BE HERE SO TEXT UPDATES WITH LANG CHANGES
    };
};

const mapDispatchToProps = dispatch => bindActionCreators(
    { removeNotificationSnackbar, setNotificationCenterOpen },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SnackbarAggregator));