import React from 'react';
import { withStyles } from '@ui-lib/core/styles';
import Button from '@ui-lib/core/Button';
import Icon from '@ui-lib/core/Icon';
import Typography from '@ui-lib/core/Typography';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Skeleton from '@ui-lib/custom-components/Skeleton';
import { getFormattedDate, getTagContent, getAction, triggerUfeEvent, triggerExternalUrl } from '../../../shared/utils';
import IconButton from '@ui-lib/core/IconButton';
import Menu from '@ui-lib/core/Menu';
import MenuItem from '@ui-lib/core/MenuItem';
import { clearFilters, setModal, setNotificationCenterOpen } from '../../../actions/NonContextualNotifications';

const styles = theme => ({
    headerInfo : {
        flex : 1,
        paddingTop : '10px',
        paddingLeft : '40px',
        paddingBottom : '14px',
    },
    headerActions : {
        fontSize : '16px',
        display : 'flex',
        alignItems : 'center',
        justifyContent : 'center',
        paddingTop : '16px',
        paddingBottom : '16px',
        paddingRight : '20px'
    },
    actionSpacing : {
        marginRight : '26px'
    },
    title : {
        fontWeight : 'bold',
        fontFamily : ' "SourceSansPro-Regular"',
        fontSize : '16px'
    }
});

const Header = ({ selectedNotification, classes, i18nProvider, isLoadingData, centerNotifications, notificationService, setNotificationCenterOpen, clearFilters, setModal }) => {

    const [anchorEl, setAnchorEl] = React.useState(null);

    const actionOnClick = (event) => {
        event.stopPropagation();
        event.preventDefault();
        setAnchorEl(event.currentTarget);
    };

    function handleClose(event) {
        if (event) {
            event.stopPropagation();
            event.preventDefault();
        }
        setAnchorEl(null);
    }

    const closeNotificationCenter = () => {
        //x:Close Notification center
        setNotificationCenterOpen(false);
        clearFilters();
    };

    const onPrimaryActionClicked = () => {

        if (selectedNotification.primaryAction && selectedNotification.primaryAction.includes('ufe://')) {
            triggerUfeEvent(selectedNotification.primaryAction, setModal, closeNotificationCenter);
        } else {
            triggerExternalUrl(selectedNotification.primaryAction);
        }
    };

    const handleDelete = () => {
        notificationService.delete(selectedNotification.id);
        handleClose();
    };

    const handleMarkAsReadOrUnread = () => {
        if (!selectedNotification.readAt) {
            notificationService.markRead(selectedNotification.id);
        } else {
            notificationService.markUnread(selectedNotification.id);
        }
        handleClose();
    };

    let showData = !isLoadingData && centerNotifications.length >= 0 && selectedNotification;
    let isEmpty = centerNotifications.length <= 0 && !isLoadingData;

    const getPrimaryActionRender = () => {
        let content = getTagContent(selectedNotification.primaryAction);
        if (typeof content === 'string') {
            if (content.includes('ufe://')) {
                return i18nProvider.Labeli18n(getAction(content));
            } else {
                return undefined;
            }
        } else {
            return content.name;
        }
    };
    let primaryActionName = selectedNotification && selectedNotification.primaryAction && getPrimaryActionRender();
    return (
        <>
            <div className={classes.headerInfo}>
                <Typography className={classes.title} variant={'h3'} component={'div'}>
                    {showData ?
                        !isEmpty && selectedNotification.title
                        :
                        !isEmpty && <Skeleton height={12}/>
                    }
                </Typography>
                <Typography variant={'caption'} component={'div'}>
                    {showData ?
                        !isEmpty && getFormattedDate(selectedNotification.createdAt, i18nProvider)
                        :
                        !isEmpty && <Skeleton height={8}/>
                    }
                </Typography>
            </div>
            <div className={classes.headerActions}>
                {showData ?
                    !isEmpty && <React.Fragment>
                        {selectedNotification.primaryAction && primaryActionName && <div className={classes.actionSpacing}>
                            <Button onClick={onPrimaryActionClicked} variant={'outlined'}>
                                {primaryActionName}
                            </Button>
                        </div>}
                        <div className={classes.action}>
                            <IconButton onClick={actionOnClick}>
                                <Icon className={'icon-extrainformation'}/>
                            </IconButton>
                            {anchorEl && <Menu anchorEl={anchorEl} open={true} onClose={handleClose}>
                                <MenuItem onClick={handleMarkAsReadOrUnread}>{selectedNotification.readAt ?
                                    i18nProvider.Labeli18n('mark_as_unread') :
                                    i18nProvider.Labeli18n('mark_as_read')}</MenuItem>
                                <MenuItem onClick={handleDelete}>{i18nProvider.Labeli18n('delete')}</MenuItem>
                            </Menu>}
                        </div>
                    </React.Fragment>
                    :
                    <React.Fragment>
                        <Skeleton height={20}/>
                    </React.Fragment>
                }
            </div>
        </>
    );
};

const mapStateToProps = ({ notificationService : { NonContextualNotifications }, shell : { i18nProvider, services } }) => {
    return {
        selectedNotification : NonContextualNotifications.selectedNotification,
        isLoadingData : NonContextualNotifications.isLoadingData,
        notificationService : services.Notifications,
        centerNotifications : NonContextualNotifications.centerNotifications,
        i18nProvider
    };
};

const mapDispatchToProps = dispatch => bindActionCreators(
    { setNotificationCenterOpen, clearFilters, setModal },
    dispatch
);

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Header));