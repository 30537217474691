import React, { Component } from 'react';
import { withTheme } from '@ui-lib/core/styles';
import { getUrlParams, getAction, triggerUfeEvent } from '../../../../shared/utils';
import Skeleton from '@ui-lib/custom-components/Skeleton';
import LoadingBody from '../LoadingBody';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { clearFilters, setModal, setNotificationCenterOpen } from '../../../../actions/NonContextualNotifications';

class Index extends Component {

    constructor(props) {
        super(props);
        //Bind here rather than using an arrow function
        //why: https://medium.com/@charpeni/arrow-functions-in-class-properties-might-not-be-as-great-as-we-think-3b3551c440b1
        this.clickHandler = this.clickHandler.bind(this);
    }

    state = {
        bodyToRender : undefined
    };

    componentDidMount() {
        const { alternativeBody } = this.props;
        this.setState({ bodyToRender : this.parseBody(alternativeBody) });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.alternativeBody !== this.props.alternativeBody) {
            this.setState({ bodyToRender : this.parseBody(this.props.alternativeBody) });
        }
    }

    parseBody = (body) => {
        const { theme } = this.props;
        const stylesBlock = `<style>a{ color: ${theme.palette.primary.main}; text-decoration: underline; cursor: pointer}</style>`;
        let hrefBlocks = body.match(/(<a.*?>)/g) || [];
        let parsedBody = body;
        for ( let i = 0; i < hrefBlocks.length; i++ ) {
            if (hrefBlocks[i].includes(`href="ufe://`) || hrefBlocks[i].includes(`href=\'ufe://`)) {
                //We dont want to do anything if it is internal link
            } else if (hrefBlocks[i].includes(`href="`) || hrefBlocks[i].includes(`href=\'`)) {
                parsedBody = parsedBody.replace(hrefBlocks[i], hrefBlocks[i].slice(0, -1) + `target="_blank" >`);
            }
        }
        return stylesBlock + parsedBody;
    };

    closeNotificationCenter = () => {
        const { setNotificationCenterOpen, clearFilters } = this.props;
        //x:Close Notification center
        setNotificationCenterOpen(false);
        clearFilters();
    };

    clickHandler = (e) => {
        // `target` is the element the click was on (the div we hooked or an element
        // with in it), `currentTarget` is the div we hooked the event on
        let el = e.target;
        while ( el && el !== e.currentTarget && el.tagName !== 'A' ) {
            el = el.parentNode;
        }
        if (el && el.tagName === 'A') {
            if (el.href.includes('ufe://')) {
                e.preventDefault();
                triggerUfeEvent(el.href, this.props.setModal, this.closeNotificationCenter);
            }
        }
    };

    render() {
        const { bodyToRender } = this.state;
        if (!bodyToRender) {
            return <LoadingBody/>;
        }
        return (
            <div onClick={this.clickHandler} dangerouslySetInnerHTML={{ __html : bodyToRender }}/>
        );
    }
}

const mapDispatchToProps = dispatch => bindActionCreators(
    { setNotificationCenterOpen, clearFilters, setModal },
    dispatch
);

export default connect(undefined, mapDispatchToProps)(withTheme()(Index));