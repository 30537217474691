import React from 'react';
import SnackbarProvider  from '@ui-lib/custom-components/Snackbar/SnackbarProvider';
import NotificationHandler from './NotificationHandler';

const SnackBar = () => {
    return (
        <SnackbarProvider>
            <NotificationHandler/>
        </SnackbarProvider>
    );
};

export default SnackBar;