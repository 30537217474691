import React from 'react';
import { connect } from 'react-redux';
import EmptyList from './StatusList/EmptyList';
import ErrorList from './StatusList/ErrorList';
import LoadingList from './StatusList/LoadingList';
import InfiniteScroll from '../../../shared/InfiniteScroll';

class ListContent extends React.Component {

    render() {
        const { preFetchDone, preFetchError, notifications } = this.props;

        if (!preFetchError && !preFetchDone)
            return <LoadingList/>;

        if (preFetchDone && preFetchError)
            return <ErrorList/>;

        if (preFetchDone && !preFetchError && notifications.length === 0)
            return <EmptyList/>;

        return <InfiniteScroll ignoreCategoryFilter={true} useNotifications={true} scrollableTarget="scrollableDiv" action={false} showSelected={false} openCenterOnItemClick={true}
                               ErrorComponent={() => <ErrorList/>}/>;
    }
}

const mapStateToProps = ({ notificationService : { NonContextualNotifications } }) => {
    return {
        notifications : NonContextualNotifications.notifications,
        preFetchDone : NonContextualNotifications.preFetchDone,
        preFetchError : NonContextualNotifications.preFetchError,
    };
};

export default connect(mapStateToProps)(ListContent);
